export const PARTNER = 0;
export const STANDALONE = 1;

export const IN = 0;
export const OUT_POSITIVE = 1;
export const OUT_NEGATIVE = 2;

export const DAILY = 0;
export const MONTHLY = 1;
export const YEARLY = 2;

export const ENTITY_TYPE = {
    service: 'service',
    endpoint: 'endpoint'
}

export const COUNTDOWN_CONSTRAINTS = {
    [ENTITY_TYPE.service]: {
        units: 'hours',
        amount: 24
    },
    [ENTITY_TYPE.endpoint]: {
        units: 'minutes',
        amount: 30
    }
};

export const IMPULSE_STATUS = {
    0: 'ACTIVE',
    1: 'PENDING',
    2: 'APPLIED',
    3: 'VERIFYING',
    4: 'COMPLETE',
    5: 'EXPIRING',
    6: 'EXPIRED'
};

export const IMPULSE_STATUS_COLOR = {
    0: 'cyan',
    1: 'orange',
    2: 'purple',
    3: 'gold',
    4: 'green',
    5: 'volcano',
    6: 'red'
};

export const ADDL_TYPE = {
    0: 'INPUT',
    1: 'CASCADER',
    2: 'TEXTAREA',
    3: 'CHECKBOX'
};

export const STRATEGY = {
    0: "DAILY",
    1: "MONTHLY",
    2: "YEARLY"
};

export const SUPPORT_CONTACT = {
    cell: '+380979381162',
    cellFormatted: '+38 (097) 938-11-62'
};

export const TELEGRAM_SUPPORT = 'https://t.me/Ottry_Support';
export const TELEGRAM_SUPPORT_FORMATTED = 't.me/Ottry_Support';

export const CONTACT_EMAIL = 'info@ottry.com';

export const FACEBOOK_LINK = 'https://www.facebook.com/OTTRYcom';

export const TICKETS_CONTACT_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';

export const SCAN_MODE_DEFAULT = 'default';
export const SCAN_MODE_FAST = 'fast';
export const SCAN_MODE_ROCKET = 'rocket';

export const DEFAULT_TAGS_SEPARATOR = ':';
export const DEFAULT_SERVICE_DESCRIPTION_SEPARATOR = ', ';

export const IMPULSE_STATUS_GENERALIZED = {
    0: 'pending',
    1: 'pending',
    2: 'pending',
    3: 'success',
    4: 'success',
    5: 'error',
    6: 'error'
};
