/**
 * @generated SignedSource<<ee0de2d8807e733e5df5295e616cfb37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "claimRequest"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "claimRequest"
      }
    ],
    "concreteType": "Impulse",
    "kind": "LinkedField",
    "name": "impulseByClaim",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "claim",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ImpulseUser",
        "kind": "LinkedField",
        "name": "impulseClient",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "client",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ImpulseService",
        "kind": "LinkedField",
        "name": "impulseService",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "service",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "warp",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "qrs",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SuccessTicketingImpulseByClaimQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SuccessTicketingImpulseByClaimQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "71f7d14d9a38a7c820d084ba6082cd22",
    "id": null,
    "metadata": {},
    "name": "SuccessTicketingImpulseByClaimQuery",
    "operationKind": "query",
    "text": "query SuccessTicketingImpulseByClaimQuery(\n  $claimRequest: ClaimRequestInput\n) {\n  impulseByClaim(request: $claimRequest) {\n    id\n    claim\n    impulseClient {\n      client {\n        id\n      }\n    }\n    impulseService {\n      service {\n        id\n        description\n        slug\n        warp\n        version\n      }\n    }\n    qrs\n    status\n  }\n}\n"
  }
};
})();

node.hash = "4ce2be1ba055672526bea1dc53d0ab8a";

module.exports = node;
