import React from 'react';
import {FormattedMessage} from 'react-intl';
import {TELEGRAM_SUPPORT, TICKETS_CONTACT_FORM} from '../../../constants';
import {LinkButton} from '../../button/button';

const CtaSectionSellOnline = () => {

    return (
        <div className='view'>
            <div className='block'>
                <div className='display-flex flex-direction-column-row padding-top-4rem-6rem'>
                    <div className='mw768-flex-1'>
                        <div className='display-sm semibold color-white'>
                            <FormattedMessage defaultMessage='Want to'/>
                            {' '}
                            <span className='background-mesh-gradient-04'><FormattedMessage defaultMessage='sell online?'/></span>
                        </div>
                        <div className='margin-top-1rem-1dot25rem text-lg color-primary-25'>
                            <FormattedMessage defaultMessage='Sign up today and start selling online tomorrow.' />
                        </div>
                    </div>
                    <div className='margin-top-2rem mw768-margin-top-0 display-flex justify-content-stretch-center flex-direction-column-row'>
                        <div className='mw768-text-align-center'>
                            <LinkButton link={{target: '_blank', rel: 'noopener noreferrer', href: TICKETS_CONTACT_FORM}} color='primary-success-gradient' size='xl' fluid='mobile'>
                                <FormattedMessage defaultMessage='Start sales'/>
                            </LinkButton>
                        </div>
                        <div className='margin-left-0-0dot75rem margin-top-0dot75rem-0 text-align-center'>
                            <LinkButton link={{target: '_blank', rel: 'noopener noreferrer', href: TELEGRAM_SUPPORT}} color='secondary-gray-dark' size='xl'  fluid='mobile'>
                                <FormattedMessage defaultMessage='Learn more'/>
                            </LinkButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CtaSectionSellOnline);