import React from 'react';

const CompleteDescription = ({title, message}) => {
    return (
        <div className='checkout-complete__description'>
            <div className='text-xl color-gray-700 semibold text-align-center'>
                {title}
            </div>
            {message &&
                <div className='text-lg color-gray-500 padding-bottom-1dot5rem text-align-center'>
                    {message}
                </div>
            }
        </div>
    )
}

export default React.memo(CompleteDescription);