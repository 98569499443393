import React from 'react';
import DoneIcon from '../../../assets/icons/done.svg';
import ErrorIcon from '../../../assets/icons-checkout/error.svg';
import NoCreditCardIcon from '../../../assets/icons-checkout/no-credit-card.svg';

export const CompleteImpulsePendingStateIcon = React.memo(() => {
    return (
        <div className='display-flex justify-content-center'>
            <NoCreditCardIcon className='display-block'/>
        </div>
    )
});

export const CompleteImpulseErrorStateIcon = React.memo(() => {
    return (
        <div className='display-flex justify-content-center'>
            <ErrorIcon className='display-block'/>
        </div>
    )
});

const CompleteImpulseStateIcon = () => {
    return (
        <div className='display-flex justify-content-center'>
            <DoneIcon className='display-block'/>
        </div>
    )
};

export default React.memo(CompleteImpulseStateIcon);