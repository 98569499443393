import React from 'react';
import {FormattedMessage} from 'react-intl';
import {LinkButton} from '../../components/button/button';
import {FACEBOOK_LINK, TELEGRAM_SUPPORT, TICKETS_CONTACT_FORM} from '../../constants';
import {Link, useLocation} from 'react-router-dom';
import MinusCircleSvg from '../../assets/icons/minus-circle.svg';
import PlusCircleSvg from '../../assets/icons/plus-circle.svg';
import MessangerGroupe from '../../assets/messenger-groupe.svg';
import CtaSectionSellOnline from '../../components/root/ogle/cta-section-sell-online';
import searchFilter from '../../searchFilter';
import classNames from 'classnames';

const FaqItem = ({question, answer}) => {
    const [showAnswer, setShowAnswer] = React.useState(false);
    return (
        <div>
            <div className='display-flex justify-content-space-between' onClick={() => setShowAnswer(!showAnswer)}>
                <div className='text-lg medium color-gray-900'>
                    {question}
                </div>
                <div className='margin-left-0dot5rem'>
                    <div className='min-height-1dot75rem display-flex align-items-center'>
                        <div className='cursor-pointer'>
                            {showAnswer ?
                                <MinusCircleSvg className='display-block btn-icon--primary height-1dot5rem width-1dot5rem'/>
                                :
                                <PlusCircleSvg className='display-block btn-icon--primary height-1dot5rem width-1dot5rem'/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {showAnswer &&
                <div className='text-md color-gray-500 margin-top-0dot5rem'>
                    {answer}
                </div>
            }
        </div>
    )
}

const Faq = () => {
    const location = useLocation();
    const QUESTIONS = [
        {
            question: <FormattedMessage defaultMessage='How do I get my receipt?'/>,
            answer: <><FormattedMessage defaultMessage='After a successful transaction, we will automatically send you email with all the details, also you can always use your'/> <Link className='color-primary-600' to={`/cabinet${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Ottry personal cabinet'/></Link>.</>
        }, {
            question: <FormattedMessage defaultMessage="I can't find the letter with the ticket in the mail! What shall I do?"/>,
            answer: <><FormattedMessage defaultMessage='View all tabs of your mailbox, use the search by mail'/> <a className='color-primary-600' href='mailto:no-reply@ottry.com'>no-reply@ottry.com</a> <FormattedMessage defaultMessage='in your email inbox and in your'/> <Link className='color-primary-600' to={`/cabinet${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Ottry personal cabinet'/></Link>.</>
        }, {
            question: <FormattedMessage defaultMessage='Can I exchange or return?'/>,
            answer: <>
                <FormattedMessage defaultMessage='Ottry provides a platform for sellers. Unfortunately, platform does not support automatic return at the moment, and we are already working on the implementation of this functionality. Therefore, for return and exchange, please contact the seller immediately at contact details on the sales page.'/>
                <FormattedMessage defaultMessage='Regarding exchange and return by the seller, sellers must work in accordance with the'/> <a className='color-primary-600' href='https://zakon.rada.gov.ua/laws/show/1023-12' target='_blank' rel='noopener noreferrer'><FormattedMessage defaultMessage='law of Ukraine on consumer protection'/></a><FormattedMessage defaultMessage=', which regulates the terms of return, return conditions, fixes the list of goods not subject to return and more.'/>
            </>
        }, {
            question: <FormattedMessage defaultMessage='How can I start using Ottry to sell my products?'/>,
            answer: <>
                <FormattedMessage defaultMessage='Fill out the'/> <a href={TICKETS_CONTACT_FORM} target='_blank' rel='noopener noreferrer' className='color-primary-600'><FormattedMessage defaultMessage='form'/></a> <FormattedMessage defaultMessage='or contact us at the contacts below.'/>
            </>
        }, {
            question: <FormattedMessage defaultMessage='Can I use Ottry on my site?'/>,
            answer: <><FormattedMessage defaultMessage='Yes.'/></>
        }, {
            question: <FormattedMessage defaultMessage='Is it possible to apply promo codes, discounts?'/>,
            answer: <><FormattedMessage defaultMessage='Yes.'/></>
        }, {
            question: <FormattedMessage defaultMessage='Where can I write wishes, suggestions, complaints?'/>,
            answer: <>
                <FormattedMessage defaultMessage='We actively communicate on'/> <a className='color-primary-600' href={FACEBOOK_LINK} target='_blank' rel='noopener noreferrer'>Facebook</a>, <a className='color-primary-600' href={TELEGRAM_SUPPORT} target='_blank' rel='noopener noreferrer'>Telegram</a> <FormattedMessage defaultMessage='and'/> <a className='color-primary-600' href='mailto:info@ottry.com'>info@ottry.com</a>
            </>
        }
    ];
    return (
        <div className='background-gradient-faq'>
            <div className='view'>
                <div className='block'>
                    <div className='padding-left-0-14rem padding-right-0-14rem'>
                        <h1 className='color-primary-600 text-md semibold padding-top-4rem text-align-center'>
                            FAQ
                        </h1>
                        <div className='padding-top-0dot75rem display-sm semibold color-gray-900 text-align-center'>
                            <FormattedMessage defaultMessage='Frequently asked questions'/>
                        </div>
                        <div className='margin-top-1rem text-lg color-gray-500 text-align-center'>
                            <FormattedMessage defaultMessage="Have questions? We're here to help"/>
                        </div>
                        <div className='padding-top-4rem-6rem'>
                            {QUESTIONS.map(({question, answer}, index) =>
                                <div key={index} className={classNames({'margin-top-2rem border-top-1px-solid border-color-gray-200 padding-top-1dot5rem': index !== 0})}>
                                    <FaqItem {...{question, answer}}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='margin-top-3rem background-color-gray-50 min-height-5rem border-radius-1rem'>
                        <div className='padding-left-1dot25rem-14rem padding-right-1dot25rem-14rem'>
                            <div className='padding-top-2rem display-flex justify-content-center'>
                                <MessangerGroupe/>
                            </div>
                            <div className='text-xl medium color-gray-900 text-align-center'>
                                <FormattedMessage defaultMessage='Questions left?'/>
                            </div>
                            <div className='text-md color-gray-500 margin-top-0dot5rem text-align-center'>
                                <FormattedMessage defaultMessage={`Didn't find the answer, have a complaint or a wish?`}/>
                            </div>
                            <div className='text-align-center margin-top-1dot5rem padding-bottom-2rem'>
                                <LinkButton link={{target: '_blank', rel: 'noopener noreferrer', href: TELEGRAM_SUPPORT}} color='primary-gradient' size='lg' >
                                    <FormattedMessage defaultMessage='Contact us'/>
                                </LinkButton>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className='margin-top-4rem padding-bottom-4rem-6rem gradient-grey-900-600-45deg-gradient-grey-800-700-26dot5deg'>
                <CtaSectionSellOnline/>
            </div>
        </div>
    );
};

export default React.memo(Faq);