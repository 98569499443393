import React from 'react';
import {Helmet} from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
import useQuery from '../useQuery';
import i18n from '../i18n';

const DEFAULT_META_TAGS = {
    '/': {
        title: 'Tickets, micromarkets, subscriptions, top-ups on ottry.com',
        titleUk: 'Квитки, мікромаркети, підписки, поповнення на ottry.com',
        description: 'Buy tickets online, grab sandwiches at micromarkets, top up via QR codes, and use recurring payments. Enjoy a unique level of comfort with every order.',
        descriptionUk: 'Купляйте квитки онлайн, сендвічі в мікромаркетах, робіть поповнення по QR-коду та використовуйте регулярні платежі. Отримуйте унікальний рівень комфорту при кожному замовленні'
    },
    '/faq': {
        title: 'FAQ – Answers to Frequently Asked Questions | Ottry',
        titleUk: 'FAQ – Відповіді на часті запитання | Ottry',
        description: 'Find answers to the most common questions about using the Ottry platform. Get information on ticket refunds, applying discounts, generating receipts, and resolving common issues.',
        descriptionUk: 'Дізнайтеся відповіді на найпоширеніші запитання про використання платформи Ottry. Отримайте інформацію про повернення квитків, застосування знижок, створення чеків і вирішення поширених проблем.'
    },
    '/promo/tickets': {
        title: 'Ottry Ticketing Service. Online Ticket Sales Platform | Ottry',
        titleUk: 'Квитковий сервіс Оттрі. Платформа для продажу квитків онлайн  | Ottry',
        description: 'A modern platform for organizing ticket sales for events of any scale. Tools for payment, analytics, and entry control, transparent pricing, and real-time support. Start selling tickets easily and efficiently!',
        descriptionUk: 'Сучасна платформа для організації продажу квитків на події будь-якого масштабу. Інструменти для оплати, аналітики та контролю входу, прозоре ціноутворення і підтримка в реальному часі. Почніть продавати квитки легко та ефективно!'
    },
    '/promo/subscriptions': {
        title: 'Recurring Subscription Payments for Business | Ottry',
        titleUk: 'Регулярні платежі за підпискою для бізнесу | Ottry',
        description: 'Use Ottry to set up automatic subscription payments. Simplify payments for your customers and reduce business routines. Flexible settings, plan management, and various pricing models to suit any needs. The perfect solution for automating recurring payments!',
        descriptionUk: 'Використовуйте Ottry для налаштування автоматичних платежів за підпискою. Спрощуйте оплату для клієнтів та зменшуйте рутину бізнесу. Гнучкі налаштування, контроль планів, різні моделі тарифів для будь-яких потреб. Ідеальне рішення для автоматизації регулярних платежів!'
    },
    '/rules': {
        title: 'Rules | Ottry',
        titleUk: 'Правила | Ottry',
        description: 'Rules and Procedure for Using the Services of Ottry',
        descriptionUk: 'Правила і порядок використання послуг компанії Ottry'
    },
    '/privacy': {
        title: 'Privacy | Ottry',
        titleUk: 'Конфіденційність | Ottry',
        description: 'Consent to the Processing of Personal Data',
        descriptionUk: 'Згода на обробку персональних даних'
    },
    '/terms': {
        title: 'Terms | Ottry',
        titleUk: 'Публічний договір | Ottry',
        description: 'Public Agreement on the Provision of Services on the Internet',
        descriptionUk: 'Публічний договір про надання послуг в мережі Інтернет'
    }
};

const SeoMetaTags = () => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const metaTags = DEFAULT_META_TAGS[location.pathname];
    if (!metaTags) return null;
    return (
        <Helmet>
            <title>{i18n(metaTags, 'title', locale)}</title>
            <meta property='og:title' content={i18n(metaTags, 'title', locale)}/>
            <meta name='description' content={i18n(metaTags, 'description', locale)}/>
            <meta property='og:description' content={i18n(metaTags, 'description', locale)}/>
        </Helmet>
    )
};

export default React.memo(SeoMetaTags);